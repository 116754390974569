import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: 'WelcomeDialog',

  props: ['open'],

  directives: {
    swiper: directive,
  },

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      step: 0,
      isOpened: this.open,
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          transitionEnd: (args) => (this.step = args.activeIndex),
        },
      },
    }
  },

  methods: {
    done() {
      localStorage.setItem('tour', true)
      this.isOpened = false
    },

    next() {
      this.$refs.swiper.$swiper.slideNext()
    },

    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
  },

  watch: {
    open() {
      this.isOpened = this.open
    },
  },
}
