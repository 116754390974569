import { mapState } from 'vuex'

import SContainer from '../../components/ui/s-container'
import SIcon from '../../components/ui/s-icon'
import SLink from '../../components/ui/s-link'
import STitle from '../../components/ui/s-title'
import WelcomeDialog from './WelcomeDialog'

import iconConfidential from '../../assets/images/icon_confidential.svg'
import iconIssues from '../../assets/images/icon_issues.svg'

export default {
  name: 'Home',

  components: {
    SContainer,
    SIcon,
    SLink,
    STitle,
    WelcomeDialog,
  },

  data() {
    return {
      iconConfidential,
      iconIssues,
      showSuccess: false,
      showWelcomeDialog: null,
    }
  },

  computed: mapState(['error', 'notice']),

  watch: {
    notice() {
      this.showSuccess = true
    },
  },
}
